import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'

import {
  wfSliderArrows,
  wfTabs,
  addSplideClasses,
  connectSplideArrows,
  sel,
  selAll,
  addStaticSplideClasses,
  connectSplideBullets,
  connectSplideCarouselBullets,
} from './utils'

gsap.registerPlugin(ScrollTrigger)

export default function Home() {
  wfSliderArrows()
  wfTabs()
  ;(() => {
    const prefix = 'prod-3'
    const el = '.' + prefix + '__slider'
    // addSplideClasses(el)
    addStaticSplideClasses(el)

    const slider = new Splide(el, {
      arrows: false,
      pagination: false,
      gap: '3rem',
      perPage: 3,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
      breakpoints: {
        991: {
          perPage: 2,
          gap: '2rem',
          // perMove: 2,
        },
        478: {
          perPage: 1,
          // perMove: 1,
        },
      },
    }).mount()
    connectSplideArrows(slider, '#prod-arrows')
    // connectSplideCarouselBullets(slider, '#prod-pg')
  })()
  ;(() => {
    const prefix = 'testimonials'
    const el = '.' + prefix + '__slider'
    addSplideClasses(el)
    addStaticSplideClasses(el)

    const slider = new Splide(el, {
      arrows: false,
      pagination: false,
      // gap: '3rem',
      perPage: 1,
      loop: true,
      speed: 1500,
      easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    }).mount()
    connectSplideArrows(slider, '#test-arrows')
    connectSplideBullets(slider, '#test-pg')
  })()
}
