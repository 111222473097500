import './style.styl'
import { sel } from './utils'
import Home from './home'
import Form from './form'
import Modal from './modal'
import Thank from './thank'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

const lenis = new Lenis()
lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'thank':
    Thank()
    break

  case 'error':
    Error()
    break
  case 'form':
    Form()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

Modal()
