import gsap from 'gsap'
import { sel } from './utils'

export default function Modal() {
  setTimeout(() => {
    const modalW$ = sel('#mod15')
    if (modalW$) {
      const showEvery = 3
      const modalBg$ = modalW$.querySelector('.mod__dim')
      const modalX$ = modalW$.querySelector('.mod__x-w')
      const modal$ = modalW$.querySelector('.mod')
      const tl = gsap.timeline({ paused: true })
      tl.to(
        modalW$,
        {
          duration: 1,
          autoAlpha: 1,
          ease: 'none',
        },
        0
      ).from(
        modal$,
        {
          duration: 1,
          y: 64,
          ease: 'power4.out',
        },
        0
      )
      function resetModal15Counter() {
        modalW$.style.opacity = 0
        modalW$.style.display = 'flex'
        gsap.to(tl, { progress: 1, duration: 1, ease: 'power4.out' })
        localStorage.setItem('modal15Counter', 1)
      }
      ;[modalBg$, modalX$].forEach((el) => {
        el.addEventListener('click', () => {
          gsap.to(tl, { progress: 0, duration: 1, ease: 'power4.out' })
        })
      })
      const modal15Counter = parseInt(localStorage.getItem('modal15Counter'))
      if (modal15Counter === -1) {
        return
      }
      if (!modal15Counter || modal15Counter >= showEvery) {
        resetModal15Counter()
      }
      if (modal15Counter < showEvery) {
        localStorage.setItem('modal15Counter', modal15Counter + 1)
      }
    }
  }, 1000)
}
